/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"GeneralPack"} description={"Info"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1nyxv9b css-42e4bw --style4 --left --full --parallax" name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/ea64a0b63eea4210b97872c526011b24_bri=115__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":"","paddingTop":151,"marginBottom":0,"paddingBottom":126}}>
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style=\"color: white;\">GeneralPack</span>"}>
              </Title>

              <Text className="text-box fs--20 w--300" style={{"maxWidth":800}} content={"<span style=\"color: var(--color-dominant);\">Vývoj a inovace obalů pro skupinové a přepravní balení</span><br><span style=\"color: var(--color-dominant);\">Snižování reálných nákladů na balení</span><br><span style=\"color: var(--color-dominant);\">Spolehlivé&nbsp;</span><span style=\"caret-color: rgb(246, 245, 241); color: rgb(246, 245, 241);\">partnerství<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"jue1i37mpw"} style={{"paddingTop":69,"paddingBottom":97,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--62" content={"O nás"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":591}} content={"Firma GeneralPack naplňuje bez kompromisu představy zákazníků o ideálním dodavateli obalových materiálů pro skupinové a přepravní balení.<br>Je naší přirozeností věnovat se každému zákazníkovi tak, aby byl přesvědčen,  že firma GeneralPack pracuje jen pro něj.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Máme více jak 30 let zkušeností v oboru.<br>Využíváme nejnovější technologie umožňující ještě dále posunovat hranice úspor ve spotřebě obalových materiálů.<br>Dodáváme největším firmám napříč průmyslovými obory.<br>Zajišťujeme logistiku na míru.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-2btyp6 css-1bih2n2 --parallax" name={"kontakt"} style={{"marginTop":0,"paddingTop":113,"paddingBottom":123}} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/6d456924ce004df58461fe3bc4264404_ove=008cffx8__s=3000x_.jpeg);
    }
  
background-position: 42% 33%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--48 ls--01" content={"<span style=\"color: white;\">Svěřte balení svých výrobků do rukou experta.</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002" use={"page"} href={"/kontakt#99poxpvd2w9"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footer"} style={{"marginTop":0,"paddingTop":43,"paddingBottom":35,"backgroundColor":"#f3f3f3"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"GeneralPack s.r.o.<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334,"marginTop":32}} content={"Pražáková 1008/69<br>639 00 Brno<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--22" content={"Rychlý kontakt"}>
              </Title>

              <Text className="text-box text-box--right" style={{"marginTop":36}} content={"Email: info@generalpack.cz<br>Tel.: +420 604 741 713<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}